// stylelint-disable declaration-no-important

//
// Utilities for common `Translate` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .t-x-block#{$infix},
    .t-y-block#{$infix} {
        cursor: pointer;
    }
    .t-x#{$infix},
    .t-x-mode#{$infix} a,
    .t-x-mode#{$infix} .t-item,
    .t-x-block#{$infix} .t-item      { 
        transition: transform .2s ease-out 0s;
        display: inline-block;
        vertical-align: top;
    }
    // .t-x#{$infix}-none,
    // .t-x-mode#{$infix}-none a:hover,
    // .t-x-mode#{$infix}-none .t-item:hover,
    // .t-x-block#{$infix}-none:hover .t-item        { 
    //     transition: none;
    // }    
    .t-x#{$infix}:hover,
    .t-x-mode#{$infix}:hover a:hover, .t-x-mode#{$infix}:focus-within a:focus,
    .t-x-mode#{$infix}:hover .t-item:hover,
    .t-x-block#{$infix}:hover .t-item, .t-x-block#{$infix}:focus-within .t-item { 
        transform: translateX(3px);
    }    

    .t-x#{$infix}-none:hover,
    .t-x-mode#{$infix}-none:hover a:hover,
    .t-x-mode#{$infix}-none:hover .t-item:hover,
    .t-x-block#{$infix}-none:hover .t-item       { 
        transform: translateX(0);
    }
    
    .t-y#{$infix},
    .t-y-mode#{$infix} a,
    .t-y-mode#{$infix} .t-item,
    .t-y-block#{$infix} .t-item     { 
        transition: transform .2s ease-out 0s;
        display: inline-block;
        vertical-align: top;
    }
    .t-y#{$infix}-none,
    .t-y-mode#{$infix}-none a:hover,
    .t-y-mode#{$infix}-none .t-item:hover,
    .t-y-block#{$infix}-none .t-item       { 
        transition: none;
    }    
    .t-y#{$infix}:hover ,
    .t-y-mode#{$infix}:hover a:hover,
    .t-y-mode#{$infix}:hover .t-item:hover,
    .t-y-block#{$infix}:hover .t-item, .t-y-block#{$infix}:focus-within .t-item      { 
        transform: translateY(-4px);
    }    
    .t-y#{$infix}-none:hover,
    .t-y-mode#{$infix}-none:hover a:hover,
    .t-y-mode#{$infix}-none:hover .t-item:hover,
    .t-y-block#{$infix}-none:hover .t-item { 
        transform: translateY(0);
    }        
  }
}
