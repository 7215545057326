//
// Utilities for common `Background` values
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .bg#{$infix}-none {
        background: none !important;
    }


    // stylelint-disable declaration-no-important

    @each $color, $value in $theme-colors {
    @include bg-variant(".bg#{$infix}-#{$color}", $value);
    }

    @if $enable-gradients {
    @each $color, $value in $theme-colors {
        @include bg-gradient-variant(".bg-gradient#{$infix}-#{$color}", $value);
    }
    }

    .bg#{$infix}-white {
    background-color: $white !important;
    }

    .bg#{$infix}-transparent {
    background-color: transparent !important;
    }

  }
}
